<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col>
        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! all flow fields                                                                                              ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row>
          <!-- -------------------------------------------------------------------------------------------------------- ->
          <! flow identification                                                                                        ->
          <!- ----------------------------------------------------------------------------------------------------------->
          <v-col>
            <v-card outlined>
              <v-card-title>task flow configuration</v-card-title>
              <v-col>
                <v-row>
                  <v-col cols="2" class="py-0 my-0">
                    <w-text-field
                      :text="flowCfg.reference"
                      label="flow reference"
                    />
                  </v-col>
                  <v-col cols="3" class="py-0 my-0">
                    <w-text-field :text="flowCfg.name" label="flow name" />
                  </v-col>
                  <v-col cols="5" class="py-0 my-0">
                    <w-textarea
                      :text="flowCfg.description"
                      label="flow description"
                    />
                  </v-col>
                  <v-col cols="2" class="py-0 my-0">
                    <v-btn color="primary" x-large @click="goToNewOrCurrentFlow"
                      >go to new or<br />current flow</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
        </v-row>

        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! Items                                                                                                        ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row>
          <v-col>
            <v-card class="elevation-6">
              <w-tabs>
                <v-tab>flows</v-tab>
                <v-tab-item>
                  <w-entity-list
                    :columns="flowsColumns"
                    :items="flowCfg.runningFlows"
                    :read-path="readFlowPath"
                    read-ref="reference"
                    title="instantiated flows"
                    :actions="flowsActions"
                    @goToNewOrCurrentFlow="goToNewOrCurrentFlow"
                    @otherAction1="otherAction1"
                    @otherAction2="otherAction2"
                    sort-by="createdAt,desc"
                  />
                </v-tab-item>

                <v-tab>display</v-tab>
                <v-tab-item>
                  <vue-mermaid-string
                    v-if="showDiagram"
                    :value="diagram"
                    @node-click="goToTask"
                  />
                </v-tab-item>

                <v-tab>tasks</v-tab>
                <v-tab-item>
                  <w-entity-list
                    :columns="taskColumns"
                    :items="flowCfg.tasks"
                    :read-path="readTaskPath"
                    read-ref="globalReference"
                    title="task configurations"
                    group-by="group name"
                  />
                </v-tab-item>

                <v-tab>groups</v-tab>
                <v-tab-item>
                  <w-entity-list
                    :columns="groupColumns"
                    :items="flowCfg.groups"
                    :read-path="readGroupPath"
                    read-ref="globalReference"
                    title="group configurations"
                  />
                </v-tab-item>
              </w-tabs>
            </v-card>
          </v-col>
        </v-row>
        <!-- --------------------------------------------------------------------------------------------------------- -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  VBtn,
  VCard,
  VCardTitle,
  VCol,
  VContainer,
  VRow,
  VTab,
  VTabItem,
} from "vuetify/lib";
import VueMermaidString from "vue-mermaid-string";
import endent from "endent";

import api from "../../components/external/modules/shared/utils/api";
import WEntityList from "../../components/external/modules/shared/views/WEntityList.vue";
import WTabs from "../../components/external/modules/shared/views/WTabs.vue";
import WTextarea from "../../components/external/modules/shared/views/WTextarea.vue";
import WTextField from "../../components/external/modules/shared/views/WTextField.vue";
import path from "../../shared/paths";
import datehourformat from "../../components/external/modules/shared/utils/datehourformat";

export default {
  components: {
    VBtn,
    VCard,
    VCardTitle,
    VCol,
    VContainer,
    VRow,
    VTab,
    VTabItem,
    VueMermaidString,
    WEntityList,
    WTabs,
    WTextarea,
    WTextField,
  },

  computed: {
    diagram: {
      get: function () {
        return this.mermaidContent;
      },
      set: function (val) {
        this.mermaidContent = val;
      },
    },
  },

  data: () => ({

    flowCfg: {
      tasks: [],
    },

    showDiagram: false,
    mermaidContent: "",

    groupColumns: [
      { value: "name", text: "name" },
      { value: "reference", text: "reference" },
      { value: "description", text: "description" },
    ],

    taskColumns: [
      { value: "name", text: "name", groupable: false },
      { value: "reference", text: "reference", groupable: false },
      { value: "manual", text: "manual", groupable: false },
      { value: "intervals", text: "intervals", groupable: false },
      { value: "group name", text: "group name", groupable: true },
    ],

    flowsColumns: [
      { value: "createdAt", text: "created" },
      { value: "reference", text: "reference" },
      { value: "customReference", text: "cust. ref." },
      { value: "statusString", text: "status" },
      { value: "startedAt", text: "started" },
      { value: "finishedAt", text: "finished" },
      { value: "totalElapsed", text: "duration" },
    ],

    readTaskPath: path.TASKCONFIG,
    readGroupPath: path.TASKGROUPCONFIG,
    readFlowPath: path.TASKFLOW,

    flowsActions: [
      {
        action: "goToNewOrCurrentFlow",
        icon: "mdi-plus",
        text: "creates a new flow, or resumes the current one",
      },
      {
        action: "otherAction1",
        icon: "mdi-lightning-bolt",
        text: "not doing much for now",
      },
      {
        action: "otherAction2",
        icon: "mdi-cloud-check",
        text: "this is just to test entity table actions!",
      },
    ],
  }),

  created() {
    this.loadFlowConfig();
  },

  methods: {
    goToTask(taskGlobalRef) {
      console.log(taskGlobalRef)
      const task = this.flowCfg.tasks.find((task) => task.globalReference === taskGlobalRef);
      this.$router.push({
        name: this.readTaskPath,
        params: { which: task.globalReference },
      });
    },

    loadFlowConfig() {
      api
        .get("taskflowconfig/display/" + this.$route.params.which)
        .then((res) => {
          if (res.data.status === 200 && res.data.entity !== null) {
            // setting the main object
            this.flowCfg = res.data.entity;
            this.flowCfg.tasks = [];

            // setting the diagram
            this.diagram = endent`${res.data.entity.mermaidString}`;
            this.showDiagram = true;

            // tweaking each task
            this.flowCfg.groups.forEach((group) => {
              group.tasks.forEach((task) => {
                // setting the group as the name of the group instead of the object
                task["group name"] = group.name;

                // adding the task to the flow
                this.flowCfg.tasks.push(task);
              });
            });

            // running flows
            if (!this.flowCfg.runningFlows) {
              this.flowCfg.runningFlows = [];
            } else {
              this.flowCfg.runningFlows.forEach((flow) => {
                flow.createdAt = datehourformat(flow._creation);
                if (flow.started) {
                  flow.startedAt = datehourformat(flow.started);
                }
                if (flow.finished) {
                  flow.finishedAt = datehourformat(flow.finished);
                }
              });
            }
          } else {
            console.log(new Error("Failed searching the task flow config"));
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    goToNewOrCurrentFlow() {
      api
        .post("taskflow/create/", this.flowCfg)
        .then((res) => {
          if (res.data.status === 200 && res.data.entity !== null) {
            // going to visit the newly created flow
            this.$router.push({
              name: path.TASKFLOW,
              params: { which: res.data.entity.reference },
            });
          } else {
            console.log(new Error("Failed creating a new task flow"));
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    otherAction1() {
      alert("just testing entity table actions!");
    },
    otherAction2() {
      alert("just testing entity table actions again!");
    },
  },
};
</script>

<style scoped>
.active-tab {
  background-color: #eef;
}
</style>